<template lang="pug">
app-layout
  .page-no-mobile
    .page-no-mobile__content.content
      titled-image.mb-8(image="phone-error" title="Страница недоступна в мобильной версии")
        | Вы можете открыть версию для компьютера

      ui-btn.page-not-found__action(
        large
        secondary
        v-on:click="onDesktopClick"
        target="_blank"
        width="233"
      ) Открыть версию для ПК

    get-apps(centered)
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import GetApps from '@/components/app/GetApps.vue';
  import TitledImage from '@/components/TitledImage.vue';
  import { UiBtn } from '@/uikit';

  export default {
    name: 'page-no-mobile',
    components: { AppLayout, UiBtn, GetApps, TitledImage },
    inject: ['openDesktop'],

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.$store.dispatch('setDesktopUrl', to.fullPath);
      });
    },

    computed: {
      desktopUrl() {
        return this.$store.getters['desktopUrl'];
      },
    },

    methods: {
      async onDesktopClick() {
        this.openDesktop({ path: this.$route.path, query: this.$route.query });
      },
    },
  };
</script>

<style lang="scss">
  .page-no-mobile {
    background-color: c('light', 'light');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 16px;
    height: 100vh;
    width: 100%;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
</style>
